import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompBlockCols from "../../components/MainStats/CompBlockCols"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
// import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'

class RonaldoManUtdStats extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    return (

      <Layout>
        <SEO
          title={"Cristiano Ronaldo Manchester United Goals and Stats"}
          description={`Cristiano Ronaldo goals, assists, hat-tricks, free kicks and other stats for Manchester United in the Premier League, the Champions League, and all other competitions.`}
          canonicalPath={`/all-time-stats/ronaldo-manchester-united-stats/`}
        />

        <h1>
          Ronaldo Manchester United Goals and Stats
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'Al Nassr Stats', 'url': '/all-time-stats/ronaldo-al-nassr-stats/' },
            { 'name': 'Juventus Stats', 'url': '/all-time-stats/ronaldo-juventus-stats/' },
            { 'name': 'Real Madrid Stats', 'url': '/all-time-stats/ronaldo-real-madrid-stats/' },
            { 'name': 'Man Utd Stats', 'url': 'none', 'active': true },
            { 'name': 'Sporting CP Stats', 'url': '/all-time-stats/ronaldo-sporting-cp-stats/' }
          ]}
        />

        <CompBlockCols>

          <CompetitionBlock competition="All Time Manchester United" team="Man United" mdata={mdata} rdata={rdata} perfmsg="[comps] since [season]" />

          <CompetitionBlock competition="Premier League - Manchester United" compTitle="Premier League" team="Man United" mdata={mdata} rdata={rdata} perfmsg="Since [season]" />

          <CompetitionBlock competition="Champions League - Manchester United" compTitle="Champions League" team="Man United" mdata={mdata} rdata={rdata} perfmsg="Since [season]" />

          <CompetitionBlock competition="Europa League - Manchester United" compTitle="Europa League" team="Man United" mdata={mdata} rdata={rdata} perfmsg="Since [season]" />

          <CompetitionBlock competition="FA Cup - Manchester United" compTitle="FA Cup" team="Man United" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="League Cup - Manchester United" compTitle="League Cup" team="Man United" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="FA Community Shield - Manchester United" compTitle="FA Community Shield" team="Man United" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="UEFA Super Cup - Manchester United" compTitle="UEFA Super Cup" team="Man United" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="Club World Cup - Manchester United" compTitle="Club World Cup" team="Man United" mdata={mdata} rdata={rdata} />

        </CompBlockCols>
        

        {/* <CopyBlock>
          <h2>Messi & Ronaldo in Games vs Each Other</h2>

          <InfoBar />

          

          <p>If you’re looking for more Messi vs Ronaldo stats, follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, where we consistenly post exclusive stats.</p>

        </CopyBlock> */}

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/man-utd/"}}) {
      edges {
        node {
          id
          competition
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/man-utd/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
  }
`

export default RonaldoManUtdStats
